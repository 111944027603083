@keyframes navslideinleft {
    from {
        transform:translate(100vw,0);
    }
    to {
        transform:translate(0,0);
    }
}


@keyframes navslideoutleft {
    from {
        transform:translate(0,0);
        display:block;
    }
    to {
        transform:translate(100vw,0);
        display:none;
    }
}
.navslideinleft {
    animation: navslideinleft 0.3s;
}
.navslideoutleft {
    animation: navslideoutleft .3s;
}

@keyframes barfadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


.barfadein {
    animation: barfadein 1s;
}
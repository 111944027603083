@tailwind base;
@tailwind components;
@tailwind utilities;
html {
    font-size:18px;
}
body {

}
.font-18 {
    font-size:18px!important;
    line-height: 20px!important;
}
.text-custom1 {
    font-size:.9rem;
    line-height: 1.4rem;
}
#reactcontainer {

}
.imgtest {
  max-width:100%;
  max-height:12vh;
  object-fit: contain;
}

li {
  list-style-type: disc;
}

#talldiv {


  }
  #widediv {

    /* position: sticky;
    top: 0;
    left: 0;
    display: flex;
    z-index: 1;
    overflow: hidden; */
  }


  .text-custom {
    font-family: "futura-pt",sans-serif;
    font-size: 18px;
  }

  .slideoverteam {

    max-width: 469px;
  }

  .ml-custom-1 {
    margin-left:42%;
  }
  .ml-custom-1-2 {
    margin-left:42%;
  }
  .mr-custom-1 {
    margin-right:15%;
    margin-left: auto;
    max-width: 700px;
  }
  .team-grid-rows {
    grid-template-rows: 1fr auto auto 1fr auto
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: white!important;
  }

  @media (min-width: 1600px) {
    .scale-modal {
      font-size: 15px !important;
      line-height: 1.4em !important;
    }
  }

  @media (max-width: 1310px) {
    .ml-custom-1 {
      position: relative;
      bottom:40px;
    }
    .ml-custom-1-2 {
      position: relative;
      bottom:75px;
    }
  }
  .mr-custom-2 {
    margin-right:35%;
  }

  .dialog-team {
    min-width:500px;
  }

  .min-w-600 {
    min-width: 550px;
  }
  .min-w-700 {
    min-width: 600px;
  }
  .img-cover {
    width:134px;
    /* height:108px; */
    object-fit: contain;
  }

  @media (min-width: 1024px) {
    .lg\:scale-75 {
      --tw-translate-x: -23px;


    }
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }
  
  .test1 {
    max-height: 86vh;
    overflow-y: scroll;
  }
  @media (max-height: 738px) {
    .test1 {
      display:flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media (min-width: 1024px) {

    .slideoverteamcontainer {
      transform: translate(600vw);
    }

  .team-overflow {
      width: 42vw;
      max-height:92vh;
      overflow-y:scroll;
    }

  }
@media (min-width: 1024px) {
    .center-text-6xl {
        margin-top: calc(50vh - 124px);
    
    }
    .center-text-6xl-2 {
        margin-top: calc(50vh - 81px);
    
    }
    .center-text-6xl-3 {
        margin-top: calc(50vh - 154px);
    
    }
    .grid-cols2-40 {
        grid-template-columns: calc(50% - 40px) 1fr;
    }

    #widediv {
      width: 800vw;
      /* transition: transform .75s; */
    }
  }

.text-custom3 {
  font-size:2.5rem;
  line-height: 2.5rem;
  margin: 0 0.2em;
  position: relative;
  bottom:5px;
}
.text-custom2 {
    font-size:2.5rem;
    line-height: 2.5rem;
    margin: 0 0.2em;
    position: relative;
    bottom:5px;

}

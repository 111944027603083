@keyframes slidein {
    from {
        transform:translate(0,-100%);
        opacity: 0;
    }
    to {
        transform:translate(0,0);
        opacity: 1;
    }
}
body {
    /* font-family: "Montserrat", sans-serif; */
    /* scroll-behavior: smooth; */
}
@keyframes slideout {
    from {
        transform:translate(0,0);
        display:block;
        opacity: 1;
    }
    to {
        transform:translate(0,-200px);
        display:none;
        opacity: 0;
    }
}
@media (min-width: 1024px) {
    .slidein {
        animation: slidein 0.8s;
    }
}

.slideout {
    /* animation: slideout 0.3s; */
}

.font-18 {
    font-size:20px!important;
}

@keyframes slideinleft {
    from {
        transform:translate(100vw,0);
    }
    to {
        transform:translate(0,0);
    }
}

@keyframes slideoutleft {
    from {
        transform:translate(0,0);
        display:block;
    }
    to {
        transform:translate(-100vw,0);
        display:none;
    }
}
@keyframes slideinright {
    from {
        transform:translate(-100vw,0);
    }
    to {
        transform:translate(0,0);
    }
}

@keyframes slideoutright {
    from {
        transform:translate(0,0);
        display:block;
    }
    to {
        transform:translate(100vw,0);
        display:none;
    }
}
.slideinleft {
    animation: slideinleft 0.3s;
}
.slideoutleft {
    animation: slideoutleft 0.3s;
}
.slideinright {
    animation: slideinright 0.3s;
}
.slideoutright {
    animation: slideoutright 0.3s;
}